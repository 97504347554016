import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faFacebookSquare,
  faInstagramSquare,
  // faTwitterSquare,
} from "@fortawesome/free-brands-svg-icons"
import { faEnvelopeSquare } from "@fortawesome/free-solid-svg-icons"

const Footer = () => (
  <footer>
    <Container>
      <Row>
        <Col>
          <div className="footerLeft">
            <ul>
              <li>
                <a
                  href="https://www.termsfeed.com/live/1fcd5440-b5e8-435c-bb7b-acb9cc023903"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Terms and Conditions
                </a>
              </li>
              <li>
                <a
                  href="https://www.termsfeed.com/live/bf3c4db7-0712-4f44-8157-fdc8c0bdf55c"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Privacy Policy
                </a>
              </li>
            </ul>
          </div>
        </Col>
        <Col>
          <div className="footerRight">
            <a
              href="https://www.facebook.com/thewellnessfactorinc"
              rel="noopener noreferrer"
              target="_blank"
            >
              <FontAwesomeIcon
                className="icon social"
                icon={faFacebookSquare}
              />
            </a>
            <a
              href="https://www.instagram.com/thewellnessfactorinc/"
              rel="noopener noreferrer"
              target="_blank"
            >
              <FontAwesomeIcon
                className="icon social"
                icon={faInstagramSquare}
              />
            </a>
            {/* <a
              href="https://twitter.com/TheWellFactInc"
              rel="noopener noreferrer"
              target="_blank"
            >
              <FontAwesomeIcon className="icon social" icon={faTwitterSquare} />
            </a> */}
            <a href="mailto:sheila@thewellnessfactor.com">
              <FontAwesomeIcon
                className="icon social"
                icon={faEnvelopeSquare}
              />
            </a>
          </div>
        </Col>
      </Row>
    </Container>
  </footer>
)

export default Footer
