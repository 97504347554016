import React from "react"
import BrushStroke from "./brushStroke"
import buddhaImage from "../images/Buddha2.png"

const Buddha = ({ className }) => {
  return (
    <div className="buddha">
      <BrushStroke
        className="brushStroke brushStrokeLeft brushStrokeTop"
        type="Left"
        text="Overcome Self Doubt"
      />
      <BrushStroke
        className="brushStroke brushStrokeRight brushStrokeTop"
        type="Right"
        text="Take Control of Your Life"
      />

      <BrushStroke
        className="brushStroke brushStrokeLeft brushStrokeMiddle"
        type="Left"
        text="Gain Self-Confidence"
      />
      <BrushStroke
        className="brushStroke brushStrokeRight brushStrokeMiddle"
        type="Right"
        text="Become the Best You"
      />

      <BrushStroke
        className="brushStroke brushStrokeLeft brushStrokeBottom"
        type="Left"
        text="Achieve Happiness"
      />
      <BrushStroke
        className="brushStroke brushStrokeRight brushStrokeBottom"
        type="Right"
        text="Achieve Peace of Mind"
      />

      <img
        src={buddhaImage}
        alt="Buddha"
        style={{
          width: "75%",
        }}
      ></img>
    </div>
  )
}

export default Buddha
