import React from "react"
import { Container, Row, Col } from "react-bootstrap"

import Buddha from "./buddha"

const Body = () => (
  <div className="Body">
    <Container>
      <Row>
        <Col>
          <h3>YOU ARE NOT ALONE</h3>
        </Col>
      </Row>
      <Row>
        <Col>
          <p>
            The traumas of everyday life can leave deeply rooted wounds in our
            unconscious mind. This damage, if untreated, can compound and have
            profound effects on how we see ourselves and the world around us.
          </p>
        </Col>
      </Row>
      <Row>
        <Col>
          <h3>WE CAN HELP</h3>
        </Col>
      </Row>
      <Row>
        <Col>
          <Buddha />
        </Col>
      </Row>
      <Row>
        <Col>
          <h3>Our Approach</h3>
        </Col>
      </Row>
      <Row>
        <Col>
          <p>
            We use carefully created integrative healing and relaxing
            experiences utilizing breathing, meditation, guided visualization
            and hypnotic techniques. Our method has been developed over many
            years by our founder.
          </p>
        </Col>
      </Row>
    </Container>
  </div>
)

export default Body
