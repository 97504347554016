import React, { useState } from "react"
import { Container, Row, Col } from "react-bootstrap"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faChevronCircleLeft,
  faChevronCircleRight,
} from "@fortawesome/free-solid-svg-icons"

const Testimonials = () => {
  const [visible, setVisible] = useState(0)

  // Must be adjusted when adding or removing testimonials
  const testimonialsCount = 4
  // const testHolder = document.getElementsByClassName("testHolder")

  const clearTestimonials = () => {
    let testHolder = document.getElementsByClassName("testHolder")
    testHolder[0].classList.add("notfocused")
    testHolder[1].classList.add("notfocused")
    testHolder[2].classList.add("notfocused")
    testHolder[3].classList.add("notfocused")
  }

  const clickRight = () => {
    let testHolder = document.getElementsByClassName("testHolder")
    clearTestimonials()
    let nextVisible = visible < testimonialsCount - 1 ? visible + 1 : 0

    testHolder[nextVisible].classList.remove("notfocused")
    setVisible(nextVisible)
  }

  const clickLeft = () => {
    let testHolder = document.getElementsByClassName("testHolder")
    clearTestimonials()
    let nextVisible = visible === 0 ? 3 : visible - 1

    testHolder[nextVisible].classList.remove("notfocused")
    setVisible(nextVisible)
  }

  return (
    <div id="testimonials" className="Testimonials">
      <h3>Kind Words from Clients</h3>
      <div
        id="slideLeft"
        onClick={clickLeft}
        onKeyDown={clickLeft}
        role="button"
        tabIndex={0}
      >
        <FontAwesomeIcon className="icon slider" icon={faChevronCircleLeft} />
      </div>
      <div
        id="slideRight"
        onClick={clickRight}
        onKeyDown={clickRight}
        role="button"
        tabIndex={0}
      >
        <FontAwesomeIcon className="icon slider" icon={faChevronCircleRight} />
      </div>
      <Container>
        <Row>
          <Col className="testHolder">
            <div className="testimonial">
              <div className="testText contentBox">
                <b>Carol B from Commack, NY</b>
                <br />
                In the time I have been working with The Wellness Factor I can
                see/feel a difference in myself. I am at peace. Life is not easy
                but my attitude toward the difficult times is one of hope and
                gratitude. I acknowledge daily the positive things in my life
                and have faith in my future.
              </div>
            </div>
          </Col>
          <Col className="testHolder notfocused">
            <div className="testimonial">
              <div className="testText contentBox">
                <b>Patrick C from Bohemia, NY</b>
                <br />I have recommended The Wellness Factor to many of my
                friends and family members. The ones that were ready to put in
                the work to get better are all seeing similar results. I thank
                God daily for putting Shelia in my life and I look forward to
                continuing our work together.
              </div>
            </div>
          </Col>
          <Col className="testHolder notfocused">
            <div className="testimonial">
              <div className="testText contentBox">
                <b>Veronica H from Copiague, NY</b>
                <br />
                My sessions with the wellness factor were a lifesaver for me. My
                mind is healthy and my soul is at peace. Life is so different
                for me now and feeling free of my hurtful past is wonderful.
              </div>
            </div>
          </Col>
          <Col className="testHolder notfocused">
            <div className="testimonial">
              <div className="testText contentBox">
                <b>Dawn G from California</b>
                <br />
                Shiela has put my mental health back into my hands by showing me
                a fresh outlook on my consciousness by letting my
                unconsciousness make decisions that weren't revealed to me by
                any other therapy. With the feedback from my vibration, I have
                moved the bar to control emotions and developed a strong belief
                in my intuition, which has opened many doors that served me well
                in every area of my life.
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Testimonials
