import React from "react"
import { Container, Row, Col } from "react-bootstrap"

const About = () => (
  <div id="about" className="About contentBox">
    <Container>
      <Row>
        <Col>
          <h5>About The Wellness Factor Inc.</h5>
        </Col>
      </Row>
      <Row>
        <Col>
          <p>
            Sheila Santi is a AC, CHt, CMT and Reiki practitioner. Ms. Santi is
            a retired drug and alcohol clinician who has been in private
            practice for over seventeen years. Ms. Santi is the creator of the
            hypnotic self-esteem building and enhancing, "Santi System for
            Emotional Repatterning" AKA "The Processes".
          </p>
        </Col>
      </Row>
      <Row>
        <Col>
          <p>
            The "Santi System" has been a major component of Ms. Santi's private
            practices for the past twelve years and as such has a proven track
            record of success for people in all walks of life. None of the
            processes ever tell you what to do or how to do it because this
            system honors the fact that each of us is unique, so you and only
            you know what is in your own best interest. The problem is accessing
            your own internal knowledge. What this new system does is allow you
            to rid yourself of the old tapes and childhood conditionings so that
            you can access your own innate wisdom. The result is a well rounded,
            positive sense of self and personal power for the user.
          </p>
        </Col>
      </Row>
    </Container>
  </div>
)

export default About
