import React, { useState } from "react"
import { Container, Row, Col, Form, Button } from "react-bootstrap"
import Swal from "sweetalert2"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSpinner } from "@fortawesome/free-solid-svg-icons"

const Contact = () => {
  const [firstName, setfirstName] = useState("")
  const [lastName, setlastName] = useState("")
  const [email, setemail] = useState("")
  const [phone, setphone] = useState("")
  const [textArea, settextArea] = useState("")
  const [isLoadeding, setIsLoadeding] = useState(false)

  const handleChange = e => {
    e.preventDefault()

    const { name, value } = e.target

    switch (name) {
      case "firstName":
        setfirstName(value)
        break
      case "lastName":
        setlastName(value)
        break
      case "email":
        setemail(value)
        break
      case "phone":
        setphone(value)
        break
      case "textArea":
        settextArea(value)
        break
      default:
        break
    }
  }

  const resetForm = () => {
    setfirstName("")
    setlastName("")
    setemail("")
    setphone("")
    settextArea("")
  }

  const handleSubmit = event => {
    event.preventDefault()
    setIsLoadeding(true)

    fetch("https://yb3fquzesa.execute-api.us-east-1.amazonaws.com/message", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        firstName: firstName.trim(),
        lastName: lastName.trim(),
        emailAddress: email.trim(),
        phoneNumber: phone.trim(),
        message: textArea.trim(),
      }),
    })
      .then(res => res.json())
      .then(
        result => {
          setIsLoadeding(false)
          Swal.fire({
            icon: "success",
            title: "Thank you, your message was received!",
            showConfirmButton: false,
            timer: 3000,
          })
          resetForm()
        },
        error => {
          setIsLoadeding(false)
          Swal.fire(
            "Unexpected Error!",
            "Sorry, Something went wrong. Please try again later.",
            "error"
          )
          resetForm()
        }
      )
  }

  return (
    <div id="contact" className="Contact contentBox">
      <Container>
        <Row>
          <Col>
            <h5>Contact Us</h5>
          </Col>
        </Row>
        <Row>
          <Col>
            <b>Email:</b>
          </Col>
        </Row>
        <Row>
          <Col>
            <p>sheila@thewellnessfactor.com</p>
          </Col>
        </Row>

        <Row>
          <Col>
            <b>Quick Message:</b>
          </Col>
        </Row>
        <Form onSubmit={handleSubmit}>
          <Form.Row>
            <Col>
              <Form.Group controlId="firstName">
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  required
                  type="text"
                  name="firstName"
                  placeholder="First Name"
                  value={firstName}
                  onChange={handleChange}
                  size="sm"
                  maxLength={25}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="lastName">
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  required
                  type="text"
                  name="lastName"
                  placeholder="Last Name"
                  value={lastName}
                  onChange={handleChange}
                  size="sm"
                  maxLength={25}
                />
              </Form.Group>
            </Col>
          </Form.Row>
          <Form.Row>
            <Col>
              <Form.Group controlId="email">
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  required
                  type="email"
                  name="email"
                  placeholder="Email Address"
                  value={email}
                  onChange={handleChange}
                  size="sm"
                  maxLength={50}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="phone">
                <Form.Label>Phone Number</Form.Label>
                <Form.Control
                  type="phone"
                  name="phone"
                  placeholder="Phone Number"
                  value={phone}
                  onChange={handleChange}
                  size="sm"
                  maxLength={12}
                />
              </Form.Group>
            </Col>
          </Form.Row>
          <Form.Row>
            <Col>
              <Form.Group controlId="textArea">
                <Form.Label>Message</Form.Label>
                <Form.Control
                  as="textarea"
                  rows="2"
                  name="textArea"
                  placeholder="Message"
                  value={textArea}
                  onChange={handleChange}
                  size="sm"
                />
              </Form.Group>
            </Col>
          </Form.Row>
          <Form.Row>
            <Col style={{ textAlign: "center" }}>
              <Button
                style={{ width: "100%" }}
                variant="normal"
                type="submit"
                size="sm"
                disabled={isLoadeding ? "disabled" : ""}
              >
                {isLoadeding ? (
                  <FontAwesomeIcon icon={faSpinner} pulse />
                ) : (
                  <span>
                    <b>SEND MESSAGE</b>
                  </span>
                )}
              </Button>
            </Col>
          </Form.Row>
        </Form>
      </Container>
    </div>
  )
}

export default Contact
