import React from "react"
import { Container, Row, Col, Button } from "react-bootstrap"

const Processes = () => (
  <div id="processes" className="Processes contentBox">
    <Container>
      <Row>
        <Col>
          <h5>The Processes.</h5>
        </Col>
      </Row>
      <Row>
        <Col>
          <p>
            The Processes are a tool to achieve self-actualization, a
            transformation that comes from within. This is achieved by you
            reprogramming the childhood conditioning that holds us back,
            allowing us to access our own internal wisdom.
          </p>
        </Col>
      </Row>
      <Row>
        <Col>
          <p>
            The Processes help you to address the wounds from within and allow
            you to unlock your own potential for change. By addressing the
            unconscious mind, we allow you to target the root of the issue.
          </p>
        </Col>
      </Row>
      <Row>
        <Col>
          <p>
            If you allow the processes to do what they can for you, by relaxing
            and letting go. You can achieve the empowerment required to take
            control of your life and well-being.
          </p>
        </Col>
      </Row>
      <Row>
        <Col>
          <a
            href="https://www.theprocesses.net?from=TWFI"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button style={{ width: "100%" }} variant="normal" size="sm">
              {" "}
              <b>LEARN MORE ABOUT THE PROCESSES</b>{" "}
            </Button>
          </a>
        </Col>
      </Row>
    </Container>
  </div>
)

export default Processes
