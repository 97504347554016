import React from "react"
import { Container, Row, Col } from "react-bootstrap"

import Logo from "./logo"

const Header = () => (
  <div className="header">
    <Container>
      <Row>
        <Col xs="3">
          <Logo />
        </Col>
        <Col xs="9">
          <nav>
            {/* <a
              // href="https://theprocesses.net"
              href="https://theprocesses.rethinkmain.com"
              rel="noopener noreferrer"
              target="_blank"
              className="navLink"
            >
              The Processes
            </a> */}
          </nav>
        </Col>
      </Row>
    </Container>
  </div>
)

export default Header
