import React from "react"

// import ComingSoon from "../components/comingsoon"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"
import Body from "../components/body"
import Testimonials from "../components/testimonials"
import Body2 from "../components/body2"
import Body3 from "../components/body3"
import Footer from "../components/footer"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Hero />
    <Body />
    <Body2 />
    <Testimonials />
    <Body3 />
    <Footer />

    {/* <ComingSoon /> */}
  </Layout>
)

export default IndexPage
