import React from "react"
import { graphql, StaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"

import Header from "./header"
import Banner from "./banner"

const HeroSection = () => (
  <StaticQuery
    query={graphql`
      query {
        hero: file(relativePath: { eq: "blue_sky_1.jpg" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1440) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => {
      return (
        <BackgroundImage
          Tag="section"
          className="heroImage"
          fluid={data.hero.childImageSharp.fluid}
          backgroundColor={`#040e18`}
        >
          <Header />
          <Banner />
        </BackgroundImage>
      )
    }}
  />
)

export default HeroSection
