import React from "react"
import brushStrokeLeft from "../images/BrushStrokeLeft.png"
import brushStrokeRight from "../images/BrushStrokeRight.png"

const BrushStroke = ({ className, type, text }) => {
  let brushStrokeType
  if (type === "Left") {
    brushStrokeType = <img src={brushStrokeLeft} alt="Brush Stroke"></img>
  } else if (type === "Right") {
    brushStrokeType = (
      <img
        src={brushStrokeRight}
        alt="Brush Stroke"
        // className={className}
      ></img>
    )
  }
  return (
    <div className={className}>
      {brushStrokeType}
      <div className="brushtext">{text}</div>
    </div>
  )
}

export default BrushStroke
