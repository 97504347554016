import React from "react"
import { Container, Row, Col } from "react-bootstrap"

import Processes from "./processes"

const Body2 = () => (
  <Container className="Body2">
    <Row>
      <Col lg="12">
        <Processes />
      </Col>
    </Row>
  </Container>
)

export default Body2
