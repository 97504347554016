import React from "react"
import { Container, Row, Col } from "react-bootstrap"

import Contact from "./contact"
import About from "./about"

const Body3 = () => (
  <Container className="Body3">
    <Row>
      <Col xl="6">
        <About />
      </Col>
      <Col xl="6">
        <Contact />
      </Col>
    </Row>
  </Container>
)

export default Body3
