import React from "react"
import { Container, Row, Col, Button } from "react-bootstrap"

// import Optin from "./optin"

const Banner = () => (
  <Container className="banner">
    <Row>
      <Col sm="12">
        <div className="heading">
          <h1>Liberate Your Life</h1>
          <div>
            <b>From the inside out</b>
          </div>
          <br />
          <a
            href="https://www.theprocesses.net?from=TWFI"
            rel="noopener noreferrer"
            target="_blank"
            className="navLink"
          >
            <Button variant="normal" size="sm">
              {" "}
              <b>THE PROCESSES</b>{" "}
            </Button>
            {/* The Processes */}
          </a>
        </div>
      </Col>
      {/* <Col sm="6"><Optin /></Col> */}
    </Row>
  </Container>
)

export default Banner
